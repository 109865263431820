import React, { Component } from 'react'

class Home extends Component {

    constructor(props) {
        super(props)

        this.state = { isExpanded: false }
    }


    handleReportExpand = (home_id) => {
        let expanded = !this.state.isExpanded;
        this.setState({ isExpanded: expanded });
        if (expanded)
            this.props.onReportExpand(home_id)
    }

    renderDateLabel = (date, dateText) => {
        let currentTime = new Date().getTime()
        let dateInMilliS = date * 1000
        let diff = currentTime - dateInMilliS
        let twoyears = 1000 * 60 * 60 * 24 * 365 * 2

        if (diff < twoyears)
            return (<label className='report_date'>{dateText}</label>)
        else
            return (<label className='report_date report_date_inactive'>{dateText}</label>)
    }

    renderQuickSummaryLabel = (wn, vpc, cp, dr, wao) => {
        if (typeof (wn) == "undefined" && typeof (wn) == "undefined" && typeof (wn) == "undefined" && typeof (wn) == "undefined" && typeof (wn) == "undefined")
            return (<label className='report_summary light-grey'>Quick Summary</label>)

        return (<label className='report_summary'>Quick Summary</label>)
    }

    renderSummaryLabel = (type, value) => {

        if (typeof (value) == "undefined")
            return (<label className='report_summary light-grey'>{type}: N/A</label>)
        else if (value > 0)
            return (<label className='report_summary red bold'>{type}: {value}</label>)
        else
            return (<label className='report_summary'>{type}: 0</label>)
    }

    renderNotificationButton = (home_id) => {
        if (this.props.isUserAuthenticated) {
            let label = '+'
            if (this.props.hasSubscription)
                label = "-"
            return (<label className="home_add_notification btn" onClick={e => this.props.onChangeSubscription(home_id)}>{label}</label>)
        }
        return ''
    }

    renderPages = (pages) => {
        if (pages > 10)
            return (<label className='report_pages red'>Pages: {pages}</label>)

        return (<label className='report_pages'>Pages: {pages}</label>)
    }

    renderScore = (score) => {
        return (<label className="total_points">{score}</label>)
    }

    renderGrade = (score) => {
        let grade = 'NA'
        let color = 'grey'
        switch (score) {
            case 1:
                grade = 'A+'
                color = 'grade_green'
                break
            case 2:
                grade = 'A'
                color = 'grade_green'
                break
            case 3:
                grade = 'B'
                color = 'grade_yellow'
                break
            case 4:
                grade = 'C'
                color = 'grade_yellow'
                break
            case 5:
                grade = 'D'
                color = 'grade_yellow'
                break
            case 6:
                grade = 'F'
                color = 'grade_red'
                break
            default:
                break
        }

        return (
            <div className='home_score_div'>
                <label className={`grade ${color}`} >{grade}</label>
            </div>
        )
    }

    renderHomeReport = (report) => {
        let report_url = `http://publicreporting.ltchomes.net/en-ca/File.aspx?RecID=${report.report_id}&FacilityID=${report.facility_id}`

        return (
            <React.Fragment>
                {this.renderDateLabel(report.report_date, report.report_title.slice(-18, -6))}
                {this.renderPages(report.report_pages)}
                {/* <label className='report_latest'>&nbsp;</label> */}
                <a className='report_title' target="_blank" rel="noopener noreferrer" href={report_url}>{report.report_title.slice(0, -18)}</a>
                {this.renderQuickSummaryLabel(report.report_summary_WN, report.report_summary_VPC, report.report_summary_CO, report.report_summary_DR, report.report_summary_WAO)}
                {this.renderSummaryLabel('WN', report.report_summary_WN)}
                {this.renderSummaryLabel('VPC', report.report_summary_VPC)}
                {this.renderSummaryLabel('CO', report.report_summary_CO)}
                {this.renderSummaryLabel('DR', report.report_summary_DR)}
                {this.renderSummaryLabel('WAO', report.report_summary_WAO)}
            </React.Fragment>
        )

    }

    render() {
        let { home, home_id, homeReports } = { ...this.props }
        let report_url = ''
        let url = ''
        if (home_id) {
            report_url = `http://publicreporting.ltchomes.net/en-ca/File.aspx?RecID=${home.latest_report_id}&FacilityID=${home.facility_id}`
            url = `http://publicreporting.ltchomes.net/en-ca/homeprofile.aspx?Home=${home_id}&tab=1`
            return (
                <div className='report' key={home_id}>
                    <a className='home_id' target="_blank" rel="noopener noreferrer" href={url}>{home_id}</a>
                    <label className='home_name'>{home.name}</label>
                    <label className='report_latest'>&nbsp;</label>
                    <label className='report_date'>{home.latest_report_title ? home.latest_report_title.slice(-18, -6) : ''}</label>
                    {this.renderPages(home.latest_report_pages)}
                    {this.renderScore(home.home_score)}
                    {this.renderGrade(home.home_grade)}

                    <a className='report_title' target="_blank" rel="noopener noreferrer" href={report_url}>{home.latest_report_title ? home.latest_report_title.slice(0, -18) : ''}</a>

                    {this.renderQuickSummaryLabel(home.latest_report_summary_WN, home.latest_report_summary_VPC, home.latest_report_summary_CO, home.latest_report_summary_DR, home.latest_report_summary_WAO)}
                    {this.renderSummaryLabel('WN', home.latest_report_summary_WN)}
                    {this.renderSummaryLabel('VPC', home.latest_report_summary_VPC)}
                    {this.renderSummaryLabel('CO', home.latest_report_summary_CO)}
                    {this.renderSummaryLabel('DR', home.latest_report_summary_DR)}
                    {this.renderSummaryLabel('WAO', home.latest_report_summary_WAO)}
                    <label className="expand" onClick={e => this.handleReportExpand(home_id)}>{this.state.isExpanded ? `▼ Hide ${home.report_total} Reports` : `► Show ${home.report_total} more reports`}</label>
                    {homeReports && this.state.isExpanded ? homeReports.map(report => this.renderHomeReport(report)) : ''}



                    {this.renderNotificationButton(home_id)}
                </div>
            )
        }
        else {
            return (
                <div className="report_loading lds-ring"><div></div><div></div><div></div><div></div></div>
            )
        }
    }

}

Home.defaultProps = {
    home: {
        name: 'Loading...',
        latest_report_title: 'Loading...',
        latest_report_pages: 0,
        latest_report_summary_WN: 0,
        latest_report_summary_VPC: 0,
        latest_report_summary_CO: 0,
        latest_report_summary_DR: 0,
        latest_report_summary_WAO: 0
    },
    home_id: '',
    reports: []
};

export default Home