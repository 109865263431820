import React, { Component } from 'react'
import '../css/App.css';
import ReportList from './ReportList'
import UserProfile from "./UserProfile"
import AddSubscription from "./AddSubscription"

import Amplify, { Auth } from 'aws-amplify'
import aws_exports from '../aws-exports'

import { getCacheData, getSubscriptions, deleteSubscription, addSubscription, getAllHomeReports } from "../helpers/DBHelper"

Amplify.configure(aws_exports);

class App extends Component {

  constructor(props) {
    super(props)

    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({ user_sub: user.attributes.sub })
      })

    this.state = ({ isUserLoggedIn: false, 
                    subscriptions: [], 
                    homesByScore: new Map(), 
                    home_report_cache: {}, 
                    user_sub: '', 
                    homesByTotalReports : new Map(), 
                    homesByReportDate : new Map(),  
                    homesByUpdateDate: new Map()
                  })
  }

  onAuthStateChange = (state) => {
    this.setState({ isUserLoggedIn: state === 'signIn' })
    if (state === 'signIn')
      getSubscriptions(this.ddbSubscriptionsDidLoad)
    if (!this.state.user_sub && state === 'signIn') {
      Auth.currentAuthenticatedUser()
        .then(user => {
          this.setState({ user_sub: user.attributes.sub })
        })
    }
  }

  onChangeHomeSubscription = (home_id) => {
    if (this.state.subscriptions.includes(home_id))
      deleteSubscription(null, home_id, this.ddbSubscriptionDidDelete)
    else
      addSubscription(null, home_id, this.state.user_sub, this.ddbSubscriptionDidAdd) //TODO: Shouldnt this have arguments?
  }

  onReportExpand = (home_id) => {
    let keys = Object.keys(this.state.home_report_cache)
    if (!keys.includes(home_id))
      getAllHomeReports(home_id, this.ddbHomeReportsDidLoad)
    else
      console.log("Cache hit: " + home_id)
  }

  ddbHomeReportsDidLoad = (home_id, items) => {
    let cache = this.state.home_report_cache;
    cache[home_id] = items
    this.setState({ home_report_cache: cache })
  }

  ddbSubscriptionDidAdd = (home_id) => {
    console.log("Added Subscripton " + home_id)
    getSubscriptions(this.ddbSubscriptionsDidLoad)
  }

  ddbSubscriptionDidDelete = (home_id) => {
    console.log("Deleted Subscripton " + home_id)
    getSubscriptions(this.ddbSubscriptionsDidLoad)
  }

  ddbCacheByTotalReportsDidLoad = (data) => {
    this.setState({homesByTotalReports : data})
  }

  ddbCacheByReportDateDidLoad = (data) => {
    this.setState({homesByReportDate : data})
  }

  ddbCacheByUpdateDateDidLoad = (data) => {
    this.setState({homesByUpdateDate : data})
  }
  
  ddbCacheDataDidLoad = (data) => {
    this.setState({ homesByScore: data })
  }

  ddbSubscriptionsDidLoad = (subs) => {
    this.setState({ subscriptions: subs })
  }

  calculateGradePct = (rating) => {

    let keys = [...this.state.homesByScore.keys()]
    if (keys.length > 0) {
      let rating_total = keys.filter(home_id => (this.state.homesByScore.get(home_id) && this.state.homesByScore.get(home_id).home_grade === rating)).length
                                        
      let total = keys.length
      let pct = ((rating_total / total) * 100).toFixed(2)
      return (`${rating_total} Homes - ${pct}%`)
    }
    else
      return "Calculating..."
  }

  componentDidMount(props) {
    // Load the cache 
    getCacheData(4, -1, this.ddbCacheDataDidLoad)
    getCacheData(1, 60, this.ddbCacheByReportDateDidLoad)
    getCacheData(2, 60, this.ddbCacheByTotalReportsDidLoad)
    getCacheData(3, 60, this.ddbCacheByUpdateDateDidLoad)
    if (this.state.isUserLoggedIn)
      getSubscriptions(this.ddbSubscriptionsDidLoad)
  }

  render() {

    let detailsHTML = <React.Fragment>
      <p className='description'><b>If you trust your loved ones to long-term care facilities, you deserve peace of mind</b> <br /><br />
        With this FREE service, you can stay in-the-know with email notifications sent the instant there's a new inspection report
      </p>

      <AddSubscription homes={this.state.homesByScore} key="1" onChangeSubscription={this.onChangeHomeSubscription} />
      <ReportList homes={this.state.homesByScore} sort="subscriptions" subscriptions={this.state.subscriptions} onChangeSubscription={this.onChangeHomeSubscription} isUserAuthenticated={this.state.isUserLoggedIn} onReportExpand={this.onReportExpand} homeReports={this.state.home_report_cache} />
    </React.Fragment>

    if (!this.state.isUserLoggedIn)
      detailsHTML = <React.Fragment>
        <p className='description'><b>If you trust your loved ones to long-term care facilities, you deserve peace of mind</b><br /><br />
          With this <b>FREE</b> service, you can stay in-the-know with email notifications sent the instant there's a new inspection report.
        </p>
      </React.Fragment>

    return (

      <div className="App">
        <header>Ontario Long Term Care Homes Spotlight</header>
        <div className="UserProfile">
          <UserProfile onAuthStateChange={this.onAuthStateChange} />
        </div>
        {detailsHTML}
        <ReportList homes={this.state.homesByScore} sort="score_good" subscriptions={this.state.subscriptions} onChangeSubscription={this.onChangeHomeSubscription} isUserAuthenticated={this.state.isUserLoggedIn} onReportExpand={this.onReportExpand} homeReports={this.state.home_report_cache} />
        <ReportList homes={this.state.homesByScore} sort="score_bad" subscriptions={this.state.subscriptions} onChangeSubscription={this.onChangeHomeSubscription} isUserAuthenticated={this.state.isUserLoggedIn} onReportExpand={this.onReportExpand} homeReports={this.state.home_report_cache} />
        <ReportList homes={this.state.homesByReportDate} sort="latest_date" subscriptions={this.state.subscriptions} onChangeSubscription={this.onChangeHomeSubscription} isUserAuthenticated={this.state.isUserLoggedIn} onReportExpand={this.onReportExpand} homeReports={this.state.home_report_cache} />
        <ReportList homes={this.state.homesByUpdateDate} sort="latest_report" subscriptions={this.state.subscriptions} onChangeSubscription={this.onChangeHomeSubscription} isUserAuthenticated={this.state.isUserLoggedIn} onReportExpand={this.onReportExpand} homeReports={this.state.home_report_cache} />
        <ReportList homes={this.state.homesByTotalReports} sort="most" subscriptions={this.state.subscriptions} onChangeSubscription={this.onChangeHomeSubscription} isUserAuthenticated={this.state.isUserLoggedIn} onReportExpand={this.onReportExpand} homeReports={this.state.home_report_cache} />

        {/* <ReportList homes={this.state.homesByScore} sizes={this.state.homes_size_list} sort="most" subscriptions={this.state.subscriptions} onChangeSubscription={this.onChangeHomeSubscription} isUserAuthenticated={this.state.isUserLoggedIn} onReportExpand={this.onReportExpand} homeReports={this.state.home_report_cache} /> */}
        <div className='faq-section'>
          <h3>Frequently Asked Questions</h3>
          <div className='faq'>
            <p className='question'>What is the purpose of this project?</p>
            <p className='answer'>As someone who is going through the process of eldery placement into the Ontatio
            Long Term Care Homes system, the first priority is collecting information on the available homes. I stumbled into
            these reports and they're full of great work from the various inspectors. In talking with friends, they
            weren't ware of these reports, and thats when I realized that if the information needs to be more
            readily accessible. There's two major deficiencies in how reports are disseminated today. The first is that there's
            no way to know when a new report is released, the second is there there's little differentiation between homes that have
            few violations and homes that are constant offenders. That's where we come in, to provide additional context to the system.
                  </p>
          </div>
          <div className='faq'>
            <p className='question'>What is an inspection report?</p>
            <p className='answer'>
              Inspections are performed by the of the Ministry of Health and Long-Term Care to determine compliance with the Long Term Care Homes Act (LTCHA) and Ontario Regulation 79/10.
              LTC Home inspectors inspect each LTC Home at least once a year. There are different types of inspections: pre-occupancy and post-occupancy for
              new Homes, complaint, critical incident, follow up and comprehensive inspections.</p>
          </div>
          <div className='faq'>
            <p className='question'>WN? VPC? CO? DR? WAO? What do they mean?</p>
            <p className='answer'>
              Inspectors are required to apply the definitions of severity, scope and history of compliance when deciding on other actions that may be taken which may include:
                  <br /><br /><b>Voluntary Plan of Correction (VPC)</b> – LTCHA , 2007, C. 8, s. 152.2
      The inspector can make a written request for the licensee to prepare a written plan of correction for achieving compliance to be implemented voluntarily.  The licensee/Home is not required to submit the plan to the ministry.  There is no required compliance date set out in the inspection report.
                  <br /><br /><b>Compliance Order (CO)</b> – LTCHA , 2007, C.8, s. 153 (1)(a) and (b)
      The inspector may order a licensee to:
      do anything, or refrain from doing anything to achieve compliance with a requirement under this Act or;
      prepare, submit and implement a plan for achieving compliance with a requirement under this Act.
      The licensee/Home is required to follow the Order to achieve compliance with the LTCHA within the timelines for compliance set out in the Order.
                  <br /><br /><b>Work and Activity Orders (WAO)</b> – LTCHA , 2007, C.8, s. 154 (1)(a) and (b)
      The inspector may order a licensee:
      to allow employees of the ministry, or agents or contractors acting under the authority of the ministry, to perform any work or activity at the LTC Home  that is necessary, in the opinion of the person making the order, to achieve compliance with a requirement under this Act; and
      to pay the reasonable costs of the work or activity.
      The licensee/Home is required to follow the Order to achieve compliance with the LTCHA within the timelines for compliance set out in the Order.
                  <br /><br /><b>Written Notification and Referral to the Director (WN & Referral (DR))</b> – LTCHA , 2007, C.8, s. 152.4
      The inspector may issue a written notification to the licensee and refer the matter to the Director for further action by the Director
            </p>
          </div>
          <div className='faq'>
            <p className='question'>How is the home letter grading determined?</p>
            <p className='answer'>Homes are rated by following point system:</p>
            <b>1 Point:</b> Per Written Notice (WN) or Voluntary Plan of Correction (VPC)
            <br /><b>10 Points:</b> Per Compliance Order (CO)
            <br /><b>100 Points:</b> Per Director's Referral (DR)
            <br /><b>1000 Points:</b> Per Work and Activity Orders (WAO), Cease of Admissions or Director's Order
            <br />
            <br />Points are accumuated over a rolling 2 year period. Points from the latest report and the latest annual quality report are <b>doubled</b>.
            Points older than a year are halved.
            <br />
            <br />Letter grades are assigned as follows:
            <br />
            <br /><b>A+:</b> Zero Points
            <br /><b>A:</b> Under 10 Points
            <br /><b>B:</b> Under 30 Points
            <br /><b>C:</b> Under 100 Points
            <br /><b>D:</b> Under 150 Points
            <br /><b>F:</b> Over 150 Points
            <br />
            <br />
          </div>
          <div className='faq'>
            <p className='question'>What's the current rating distribution of the Ontario LTCH Homes?</p>
            <p className='answer'>
              <b>A+:</b> {this.calculateGradePct(1)}
              <br /><b>A:</b> {this.calculateGradePct(2)}
              <br /><b>B:</b> {this.calculateGradePct(3)}
              <br /><b>C:</b> {this.calculateGradePct(4)}
              <br /><b>D:</b> {this.calculateGradePct(5)}
              <br /><b>F:</b> {this.calculateGradePct(6)}
              <br />
              {/* TODO: Hack */}
              <br />There are <b>{this.state.homesByScore ? this.state.homesByScore.size: "Loading..."}</b> rated homes, with an issued report in the last two years.
            </p>
          </div>
          <div className='faq'>
            <p className='question'>What's the reasoning behind the letter grading?</p>
            <p className='answer'>Theroetically, all homes should always be compliant with the <a href="https://www.ontario.ca/laws/statute/07l08">Long Term Care Homes Act 2007</a>,
            so the expected score is zero. We award these homes with a very difficult to obtain A+. They earned it. There's two things to remember about the inspection
            system: There's only a single annual review, and only additional reviews if a complaint is recieved. This means than an inspector may only spend a few days in a home per year.
            This makes their reports carry serious weight and we have to give importance to every infraction documented.<br/><br/>WN and VPCs can happen and if they get addressed expediently,
            then the home will get an A or B rating. A more serious issue will be given a Compliance Order. These will force the home to fall to a C or D rating. If an issue is
            persistent enough that the inspector feels the need to escalate the issue to the Director, that's a red flag to us as well. Any home that has a director's
            referral (or worse a cease of admissions) will immediately be assigned a failing F grade.
            <br/><br/>
                Not all reports are parsable by this tool. When that happens, we make a simple guess that a report with more than 5 pages is likely bad and we penalize a home 1 point
                per page over 5 pages.
            
            </p>
          </div>
          <div className='faq'>
            <p className='question'>Wow that's a lot of homes rated under a C!</p>
            <p className='answer'>Maybe it's a statement of the state of our LTCH system and we need to boil these reports down into a simple consumable letter to draw
            appropriate attention to the problems so they can get fixed and improve our resident seniors daily lives.
            <br/><br/>The other thing to remember is that these grades are given as a guide. We can't determine the severity of the infractions, so we paint them all with the same brush.
                    A clarical infraction for failing to publically post a report is given the same weight as a failure to administrer drugs at the correct time. These would both be
                    categorized as a WN but one is obvsiouly more relevant than the other.
            </p>
          </div>
          <div className='faq'>
            <p className='question'>How many emails can I expect? How often?</p>
            <p className='answer'>It appears that your average home only gets about 3-4 reports per year. One annual inspection and
                  then any incident reports as needed.</p>
          </div>
          <div className='faq'>
            <p className='question'>When are notification emails sent?</p>
            <p className='answer'>The search for new reports runs in the morning and triggers any notifications that need to be sent.</p>
          </div>
          <div className='faq'>
            <p className='question'>What does a notification email look like?</p>
            <p className='answer'>You can view a sample report <a href='/report_sample.pdf'>here</a></p>
          </div>
          <div className='faq'>
            <p className='question'>How do I unsubscribe?</p>
            <p className='answer'>You can unsubscribe from all emails by deleting your account.</p>
          </div>
          <div className='faq'>
            <p className='question'>This service is free? How?</p>
            <p className='answer'>Thanks to Amazon Web Services Serverless architecture, this service costs about $3/month to run and
                  the code was written over the course of a few weeks courtesy of <a href='https://two.pizza'>Two Pizzas Consulting</a></p>
          </div>
          <div className='faq'>
            <p className='question'>What is your privacy policy?</p>
            <p className='answer'>We will never sell your information. This is strictly a free service meant to aide families
                  that have loved ones in the Ontario system. You will never recieve a promotional email from this service.</p>
          </div>
          <div className='faq'>
            <p className='question'>I have question / problem / feature request...</p>
            <p className='answer'>Send an email to <a href='mailto:amore@two.pzza'>amore@two.pizza</a> and we'll see what we can do.</p>
          </div>
        </div>
        <div className='footer-links'>
          <h3 className='links'>Other Long Term Care Home Info</h3>
          <a href='http://publicreporting.ltchomes.net/en-ca/Default.aspx'>Inspection Reports</a>
          <a href="https://hssontario.ca/important-links/long-term-care-wait-times-and-waitlists">Current Waitlists</a>
          <a href="http://publicreporting.ltchomes.net/en-ca/LHIN_Map.aspx">Find Homes on a Map</a>
          <a href="https://www.ontario.ca/page/long-term-care-home-complaint-process">LTCH Complaint Process</a>
          <a href="https://www.ontario.ca/laws/statute/07l08">Long Term Care Homes Act 2007</a>
          <a href="https://www.hqontario.ca/System-Performance/Long-Term-Care-Home-Performance">Current LTCH Performance</a>
          <a href="https://longtermcareinquiry.ca/en/">The Public Inquiry into the Safety and Security of Residents in the Long-Term Care Homes System</a>
          <a href="https://www.hqontario.ca/Portals/0/documents/system-performance/hqo-2018-long-term-care-indicators.xlsx">HQO Long Term Care Indicators</a>
          <a href="https://www.oltca.com/oltca/OLTCA/Public/LongTermCare/FactsFigures.aspx">Ontario Long Term Care Facts and Figures</a>
          <a href="http://www.auditor.on.ca/en/content/annualreports/reportsbytopic/bytopic_health.html">Auditor General's Reports for LTCH</a>
        </div>
      </div>
    )
  }
}

export default App;
