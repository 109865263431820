import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'

const TABLE_NAME='LTCH'

export const getCacheData = (mode, limit, callback) => {

    Auth.currentCredentials()
        .then(credentials => {
            const ddb = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-10-08', credentials: Auth.essentialCredentials(credentials), region: 'us-east-2' })

            let params = {
                TableName: TABLE_NAME,
                ExpressionAttributeValues: {
                    ":u1": 'cache'
                },
                KeyConditionExpression: "part = :u1"
            }
            switch (mode) {
                case 1:
                    params.IndexName = 'CacheByReportDate'
                    params.ScanIndexForward = false
                    break
                case 2: 
                    params.IndexName = 'CacheByTotalReports'
                    params.ScanIndexForward = false
                    break
                case 3: 
                    params.IndexName = 'CacheByUpdateDate'
                    params.ScanIndexForward = false
                    break
                case 4: 
                    params.IndexName = 'CacheByScore'
                    break
                case 5: 
                    params.IndexName = 'CacheByScore'
                    params.ScanIndexForward = false
                    break
                default:
                    break
            }
            switch (limit) {
                // All
                case -1:
                    break
                default:
                    params.Limit = limit
            }

            ddb.query(params, function (err, data) {
                if (err) {
                    console.log(err)
                    callback({})
                }
                else {
                    const myMap = new Map(data.Items.map(obj => [obj.sort, obj]));
                    callback(myMap)
                }
            })
        })
}

export const getSubscriptions = (callback) => {

    Auth.currentUserCredentials().then(credentials => {
        const ddb = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-10-08', credentials: Auth.essentialCredentials(credentials), region: 'us-east-2' })

        let params = {
            TableName: TABLE_NAME,
            ExpressionAttributeValues: {
                ":u1": credentials.identityId
            },
            KeyConditionExpression: "part = :u1",
            ProjectionExpression: 'sort'
        }

        ddb.query(params, function (err, data) {
            if (err) {
                console.log(err)
                callback([])
            } else {
                let ids = data.Items.map(result => result.sort) 
                callback(ids) 
            }
        })
    }).catch(err => {
        console.log(err);
        callback([])
    })
}

export const addSubscription = (event, home_id, user_sub, callback) => {
    //event.preventDefault()

    Auth.currentUserCredentials().then(credentials => {

        let ddb = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-10-08', credentials: Auth.essentialCredentials(credentials), region: 'us-east-2' })

        let params = {
            TableName: TABLE_NAME,
            Item: {
                'part': credentials.identityId,
                'sort': home_id,
                'sub_id': user_sub
            }
        }

        ddb.put(params, function (err, data) {
            if (err) {
                console.log(err)
                callback({})
            } else {
                callback(data)
            }
        })

    })

}

export const deleteSubscription = (e, home_id, callback) => {
    //e.preventDefault()
    console.log('Chould delete this subscripts: ' + home_id)
    console.log(e)
    Auth.currentCredentials()
        .then(credentials => {

            let ddb = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-10-08', credentials: Auth.essentialCredentials(credentials), region: 'us-east-2' })

            let params = {
                TableName: TABLE_NAME,
                Key: {
                    'part': credentials.identityId,
                    'sort': home_id
                }
            }
            ddb.delete(params, function (err, data) {
                if (err) {
                    console.log(err)
                    callback(null)
                }
                else {
                    console.log(data)
                    callback(null)
                    // console.log(this)
                    // this.refreshSubscriptions(null)
                }


            })
        })
}

export const getAllHomeReports = (home_id, callback) => {

    Auth.currentCredentials()
        .then(credentials => {
            const ddb = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-10-08', credentials: Auth.essentialCredentials(credentials), region: 'us-east-2' })
            let params = {
                TableName: TABLE_NAME,
                ExpressionAttributeValues: {
                    ":u1": home_id
                },
                KeyConditionExpression: "part = :u1",
                ScanIndexForward: false
            }
            ddb.query(params, function (err, data) {
                if (err) {
                    console.log(err)
                    callback(home_id, [])
                }
                else {
                    callback(home_id, data.Items.slice(1))
                }
            })
        })
}

