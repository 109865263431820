// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:12a82d95-2b36-4d27-843a-9edcd2f6a8cd",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_acDsLcWBB",
    "aws_user_pools_web_client_id": "3veipqnrd4akqo8udcnnu5kf3g",
    "aws_content_delivery_bucket": "ltch.report",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3trk9jnuoqo8e.cloudfront.net"
};


export default awsmobile;
