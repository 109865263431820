import React, { Component } from 'react';
import Select from 'react-select'


class AddSubscription extends Component {

    constructor(props) {
        super(props)

        this._option = null
        this._selectedOption = 0

    }

    handleChange = (selectedOption) => {
        if (selectedOption.value)
            this._selectedOption = selectedOption.value;
        else
            this._selectedOption = ''
    }

    addNotification(event) {
        event.preventDefault()
        let home_id = this._selectedOption
        this.props.onChangeSubscription(home_id)
    }

    render() {
        let optionsList = []
        if (this.props && this.props.homes) {
            let sorted = [...this.props.homes.keys()].sort((a, b) => this.props.homes.get(a).name.localeCompare(this.props.homes.get(b).name))

            sorted.forEach(key => {
                optionsList.push({ value: key, label: this.props.homes.get(key).name + ' (' + key + ')' })
            })
        }

        return (
            <div className='addSubscription'>
                <h3>Add Subscription</h3>
                <Select options={optionsList} onChange={this.handleChange} ref={e1 => this._option = e1} />
                <label className='btn green add_notification' name="addSubscription" onClick={(event) => { this.addNotification(event) }} >+</label>
            </div>
        )
    }

}



export default AddSubscription;