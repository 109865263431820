/*
- Should have a user sign in button, then the amazon crap, then the logged in stuff. 
*/


import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Hub } from 'aws-amplify';
//import { Logger } from 'aws-amplify';

import { Authenticator } from 'aws-amplify-react/dist/Auth';

class UserProfile extends Component {

    constructor(props) {
        super(props)

        this.state = ({ authState: 'signOut', isDeleteAccountRequested: false, isSignoutPressed: false })
    }

    componentDidMount() {
        Hub.listen('auth', this, "UserProfileListener")
        this.setUserStateDetails()
    }

    setUserStateDetails = () => {
        Auth.currentAuthenticatedUser().then(user => {
            this.setState(
                {
                    authState: 'signIn',
                    username: user.username,
                    email: user.attributes.email
                }
            )
            this.props.onAuthStateChange('signIn')
        }
        ).catch(err => console.log("Auth Error: " + err))

    }

    signUserOut() {
        Auth.signOut()
    }

    startDeleteAccount(e) {
        this.setState({ isDeleteAccountRequested: true });
    }

    deleteAccount = (e) => {
        // We won't bother deleting the account now, we can do that when we batch out notification emails and the user identity can't be found anymore. 
        console.log("Delete the account! NOooo")
        Auth.currentAuthenticatedUser().then(user => {
            this.setState({ authState: 'signOut', isDeleteAccountRequested: false })
            user.deleteUser()
            document.location.reload()
            console.log("The user was deleted")
        })
    }



    onHubCapsule(capsule) {
        console.log("Auth Capsule Recieved: " + capsule.payload.event)
        switch (capsule.payload.event) {
            case 'signIn':
                this.setUserStateDetails()
                break
            case 'signUp':
                //Let the auth component do it's thing
                break
            case "signOut":
                this.setState({ authState: 'signOut', isDeleteAccountRequested: false })
                this.props.onAuthStateChange('signOut')
                break;
            case 'signIn_Failure':
                this.setState({ authState: 'signOut', isDeleteAccountRequested: false })
                this.props.onAuthStateChange('signOut')
                break;
            case 'configured':
                this.setUserStateDetails()
                break;
            default:
                break;
        }
    }

    loginButtonClicked = (e) => {
        this.setState({ isSignoutPressed: true })
    }

    render() {

        let deleteAccountHtml =
            <React.Fragment>
                <button  className='blue signout' onClick={this.signUserOut}>Sign Out</button>
                <button  className="red delete_account_btn" onClick={e => this.startDeleteAccount(e)}>Delete Account</button>
            </React.Fragment>

        if (this.state.isDeleteAccountRequested) {
            deleteAccountHtml = <React.Fragment>
                <button  className='red delete_account_btn_final' name="Confirm Account Deletion" onClick={e => this.deleteAccount(e)}>Confirm Delete Account</button>
                <button  className='blue cancel' name="Cancel" onClick={e => this.setState({ isDeleteAccountRequested: false })}>Cancel</button>
            </React.Fragment>
        }

        let output = !this.state.isSignoutPressed ? <button className='blue signIn' onClick={e => this.loginButtonClicked(e)}>Create Account / Sign In</button> : <Authenticator />
        if (this.state.authState === 'signIn') {
            output = <React.Fragment>
                <p className="username">Hi {this.state.username}, we're sending notifications to '{this.state.email}'</p>

                {deleteAccountHtml}
            </React.Fragment>
        }


        return (
            <React.Fragment>
                {output}
            </React.Fragment>
        )
    }

}


export default UserProfile