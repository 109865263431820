import React, { Component } from 'react';
import Home from "./Home.js"

class ReportList extends Component {

    constructor(props) {
        super(props)

        this.state = { 'page': 0 }
    }

    getHomeIdsToRender = (pageNumber, pageSize) => {

        if (this.props.homes.size <= 0) return [null, null, null, null, null]

        if (this.props.sort === "subscriptions") {
            return this.props.subscriptions ? this.props.subscriptions : []
        }

        let keys = [...this.props.homes.keys()]
        if (this.props.sort === "score_bad") {
            keys = Array.from(keys).reverse()
        }

        return keys.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize)
    }

    getTotalItems = () => {
        if (this.props.homes)
            return this.props.homes.size
        else
            return 0
    }


    handleNextPage(event) {
        event.preventDefault()
        this.setState({ page: this.state.page + 1 })
    }

    handlePrevPage = (event) => {
        event.preventDefault()
        this.setState({ page: this.state.page - 1 })
    }

    renderSectionTitle = (sort) => {

        let text = ''
        if (sort === "latest_date")
            text = "Latest Inspection Dates"
        else if (sort === "latest_report")
            text = "Latest Issued Dates"
        else if (sort === "subscriptions")
            text = "Current Subscriptions"
        else if (sort === "score_good")
            text = "Top Rated"
        else if (sort === "score_bad")
            text = "Lowest Rated"
        else
            text = "Most Reports"

        return (<h3>{text}</h3>)
    }


    renderHome = (home_id) => {
        let htmlOut = "Downloading Reports"
        let isSubbedHome = this.props.subscriptions ? this.props.subscriptions.includes(home_id) : false
        if (home_id)
            htmlOut = <Home home={this.props.homes.get(home_id)} home_id={home_id} key={home_id} hasSubscription={isSubbedHome} onChangeSubscription={this.props.onChangeSubscription} isUserAuthenticated={this.props.isUserAuthenticated} onReportExpand={this.props.onReportExpand} homeReports={this.props.homeReports[home_id]} />
        else
            htmlOut = <Home />

        return htmlOut
    }

    renderNext(page, total_items) {
        let html = <label className="next btn blue" href='#' onClick={e => this.handleNextPage(e)} >▶</label>
        if (page > 50 || total_items <= (page + 1) * 6 || this.props.sort === "subscriptions")
            html = <label href='#' className="next btn light-grey" onClick={e => e.preventDefault()}>▶</label>

        return html
    }

    renderPrev(page, total_items) {
        let html = <label className="prev btn blue" href='#' onClick={e => this.handlePrevPage(e)}>◀</label>
        if (page <= 0 || total_items <= 6 || this.props.sort === "subscriptions")
            html = <label href='#' className="next btn light-grey" onClick={e => e.preventDefault()}>◀</label>

        return html
    }

    render() {
        let ids = this.getHomeIdsToRender(this.state.page, 6)
        if (ids === null)
            return "<center>No Homes Found</center>"
        return (
            <React.Fragment>
                {this.renderSectionTitle(this.props.sort)}
                {ids.map(this.renderHome)}
                <div className="next_prev">
                    {this.renderPrev(this.state.page, this.getTotalItems(this.props.sort))}
                    <label className="page_num">Page {this.state.page + 1}</label>
                    {this.renderNext(this.state.page, this.getTotalItems(this.props.sort))}
                </div>
            </React.Fragment>
        );
    }
}

export default ReportList
